@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,700;1,100;1,200;1,300;1,400;1,700&family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;1,300;1,400;1,600;1,700&display=swap);
.ebonyClay {background-color: rgb(47,52,70);color: rgb(217,230,233);}
.swissCoffee {background-color: rgb(217,211,208);color: rgb(115,23,107);}
.martiniqueCopper {background-color: rgb(52,55,81);color: rgb(194,133,54);}
.voodooFireBush {background-color: rgb(75,54,86);color: rgb(230,164,40);}
.pickledBluewoodJaffa {background-color:rgb(43,67,85);color:rgb(246,112,71);}
.martiniqueBourbon {background-color:rgb(43,45,73);color:rgb(195,124,25);}
.shipGrayMischka {background-color:rgb(57,49,62);color:rgb(228,222,231);}

.Mischka {color:rgb(228,222,231);}

:root {
    /*--header-bg-color: rgb(57,49,62);
    --header-font-color: rgb(228,222,231);*/

    --header-bg-color: rgb(49, 49, 49);
    --header-font-color: rgb(228,222,231);

    --icon-color:rgb(57,49,62);
    --date-Color:rgba(57,49,62,0.8);

    --link-underline-color:rgb(217,211,208);

    --link-color:rgb(43,45,73);
    --link-color-hover:rgb(194,133,54);

    --header-border-color:rgb(228,222,231);
    --light-border-color:rgba(217,211,208,0.6);
    --dark-border-color:hsl(0, 0%, 35%);

    --body-bg:rgb(26,27,37);
    --body-dark-bg: hsl(0, 0%, 19%);

    --other-headers-bg:hsl(0, 0%, 100%);

    --cover-letter-bg: rgb(49, 49, 49);
    --cover-letter-head-bg: rgb(255, 255, 255);
    --cover-letter-body-color: rgb(255,255,255);
    --cover-letter-head-color: rgb(49, 49, 49);
    --cover-letter-light-italic: rgb(183, 183, 183);
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

ul {
    list-style: none;
}


body {
    background-color: rgb(26,27,37);
    background-color: var(--body-bg);
    font-family: 'Montserrat', sans-serif;
    font-size: 15px;

}
h1,h2,h3,h4 {
    font-family: 'Open Sans', sans-serif;
}
form {
    display:none;
}


a {
    border: 0 solid transparent;
    border-bottom-width: 2px;

    text-decoration: none;
    transition: border-bottom-color 0.4s;

    color: rgb(43,45,73);

    color: var(--link-color)
}
a:hover {
    border-color: chocolate;
    color: rgb(194,133,54);
    color: var(--link-color-hover)
}

.white-svg {
    color:white;
}

.pageSeperator {
    position: fixed;
    border-top:1px solid red;
    width: 100%;

    z-index: 999;
    left:0;
    top:297mm;
}
.App {
    background-color: white;
    min-height:100vh;
    width:100%;
    max-width: 210mm;
    margin:auto;
    box-shadow: black 0px 0px 15px;


    display: grid;
    grid-template-areas:
            "head           head"
            "main           aside"
            "other          other"
            "coverLetter    coverLetter"
            "foot           foot";

    grid-column-gap: 0;
    grid-column: auto;
    grid-template-columns: 2fr 1fr;
    grid-template-rows: 175px;
}


img {
    max-width: 100%;
}

header {
    background-color: rgb(49, 49, 49);
    background-color: var(--header-bg-color);
    color: rgb(228,222,231);
    color: var(--header-font-color);
    padding:15px 0;
    grid-area:head;

    text-align: center;
}
header h1 {
    padding-bottom: 18px;
    margin: auto auto -16px;
    border-bottom:2px solid rgb(228,222,231);
    border-bottom:2px solid var(--header-border-color);

    width:70%;
}

header h2 {
    display: inline-flex;
    padding:0 15px;
    background-color: rgb(49, 49, 49);
    background-color: var(--header-bg-color);
}


.basicInfo{
    padding-top:20px;
    display: flex;
    justify-content: space-evenly;
    text-align: center;
}
.basicInfo>div {
    width:150px;
}
.phone{}
.email{}
.location{}


main {
    padding:33px 40px;
    grid-area: main;
    box-shadow: 5px 0 3px rgba(0,0,0,0.1);
}

main>article, aside>article {
    padding-top: 15px;
    padding-bottom: 15px;
}
main>article:first-child, aside>article:first-child {
    padding-top: 0;
}
main>article:last-child, aside>article:last-child {
    padding-bottom: 0;
}

main>article>h3, aside>article>h3 {
    border-bottom: black 1px solid;
    margin-bottom: 5px;
    font-style: italic;
    font-weight: 300;
}

.skills {}
.skillsList {
    display: flex;
    flex-wrap:wrap;
    font-size: 0.8rem;
}
.skill {
    margin:6px 10px;
    border: 0 solid rgba(217,211,208,0.6);
    border: 0 solid var(--light-border-color);
    border-bottom-width: 1px;
    font-size:12px;
}


.experiences {}
.experienceList {}

.experience {
    padding-top:10px;
    padding-bottom:10px;
    border-bottom:1px solid rgba(217,211,208,0.6);
    border-bottom:1px solid var(--light-border-color);
}
.experience:first-child {padding-top: 0;}
.experience:last-child {padding-bottom: 0;border-bottom: 0;}

.expTitle {}
.expTitle small {
    font-weight: 300;
}
.expDate {
    font-weight: 300;
}
.date {
    font-size: 0.8em;
    margin-top: -2px;
}
.description {
    font-size: 0.85em;
}
.descList {
    padding-left:20px;
    font-size: 0.90em;
    margin-top:5px;
}
.descList li:before {
    content: "\2022";
    font-weight: bold;
    display: inline-block;
    color:rgba(57,49,62,0.8);
    width: 1em;
    margin-left:-1em;
}


.publications {}
.publicationsList {}
.publication {}
.publication p {
    font-size: 0.9em;
    margin-bottom: 10px;
}
.pubLang {
    display: flex;
    font-style: italic;
    font-weight: lighter;
}
.pubLang li {
    margin:0 10px;
}
.pubLang li:first-child {margin-left:0;}
.pubLang li:last-child {margin-right:0;}

.pubLink {
    color:gray;
    display: flex;
    font-style: italic;
}

.pubLink svg {max-width: 16px;}


aside {
    padding:33px 40px;
    grid-area: aside;
    background-color: rgba(61,61,61,0.06);
}

.strengths ul li {
    padding-bottom: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    border: 0 solid rgba(217,211,208,0.6);
    border: 0 solid var(--light-border-color);
    border-bottom-width: 1px;
}
.strengths ul li:last-child {
    margin-bottom: 0;
    border:0;
}

.strength, .expDate, .eduDate {
    display: flex;
    align-items: center;
}
.strength>div:last-child, .expDate>div:last-child, .eduDate>div:last-child {
    padding-left: 5px;
    margin-top:-2px;
}

.expDate,.eduDate {
    color:rgba(57,49,62,0.8);
    color:var(--date-Color);
}
.expDate svg, .eduDate svg {
    max-width: 16px;
}

.strength svg, .sosMedIcon svg{
    max-width: 20px;
    color: rgb(57,49,62);
    color: var(--icon-color);
}

.sosMed {}
.sosMedIcon {
    display: flex;
    align-items: center;
}
.sosMedIcon svg {
    max-width: 16px;
    margin-top:4px;
    margin-bottom: 0;
}

.sosMedIcon div:last-child {
    padding-left:8px;
    margin-top:-1px;
}

.otherProjects {
    grid-area: other;
}

.projects {
    min-height: 297mm;
    margin-top:20px;
    background-color: hsl(0, 0%, 19%);
    background-color: var(--body-dark-bg);
}
.projectsHeader {
    padding: 20px 40px;
    background-color: hsl(0, 0%, 100%);
    background-color: var(--other-headers-bg);
}
.projectsList {
    background-color: hsl(0, 0%, 19%);
    min-height: 20px;
    display: flex;
    width: 100%;
    flex-flow: wrap;
}
.project {
    text-align: center;
    padding:30px 40px 50px;
    width: 50%;
    font-size: 0.7em;
    color: rgb(255,255,255);
    color: var(--cover-letter-body-color);
    position:relative;
}
.projectImage {
    position: relative;
    box-shadow: 1px 2px 5px 1px #000;
    cursor: pointer;

}
.projectImage img{
    margin-bottom:-3px;
    max-height: 180px;
}

.project h3{
    margin-top:20px;
}
.project h3 a {
    color: rgb(255,255,255);
    color: var(--cover-letter-body-color);
}
.project small {
    font-size:0.8em;
    font-style: italic;
    color:rgb(183, 183, 183);
    color:var(--cover-letter-light-italic);
}
.project p {
    margin:10px 0;
}
.project .no-link{
    margin-bottom: 22px;
}

.project:nth-child(odd) {
    border-right: 1px dashed hsl(0, 0%, 35%);
    border-right: 1px dashed var(--dark-border-color);
}
.project:nth-child(n+3) {
    border-top: 1px dashed hsl(0, 0%, 35%);
    border-top: 1px dashed var(--dark-border-color);
}
.project.almostLast{
    border-bottom:1px dashed hsl(0, 0%, 35%);
    border-bottom:1px dashed var(--dark-border-color);
    padding-bottom: 51px;
    margin-bottom: -1px;
}

.projectFlexInfo {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}

.projectTools {
    position: absolute;
    bottom:10px;
    left:50%;
    transform: translate(-50%, -10px);

}

.projProgList img{
    width:100%;
    max-width: 15px;
}

.projProgList {
    margin-top:10px;

    display: flex;
    align-items: center;
    justify-content: center;
}
.projProgList li {
    padding:0 5px;
}
.mainCoverLetter{
    grid-area: coverLetter;
}

.coverLetter {
    min-height: 297mm;
    padding-bottom:30px;
    width: 100%;
    background-color: rgb(49, 49, 49);
    background-color: var(--cover-letter-bg);
}

.coverLetter>div {
    padding:60px 80px;
}
.coverLetter>div:last-child {
    padding-bottom: 0;
    padding-top:10px;
}

.coverLetter .Head{
    min-height: 175px;
    background-color: rgb(255, 255, 255);
    background-color: var(--cover-letter-head-bg);
    position: relative;
    box-shadow:0 30px 36px -30px rgba(0,0,0,0.5);
}
.coverLetter .Head h2 {
    font-weight: 300;
    font-size: calc(1em * 2);
}

.coverLetter>.Head>.time {
    position: absolute;
    bottom: 40px;
    left: 80px;
    width: 100%;
    max-width: calc(100% - 160px);
}
.coverLetter>.Head>.time img {
    position: absolute;
    max-width: 80px;
    right: 1px;
    top: -41px;
    border-radius: 4px;
    box-shadow: #282c34 0px 0px 10px -4px;

}

.coverLetter .Signature{
    min-height: 40px;
    color: rgb(255,255,255);
    color: var(--cover-letter-body-color);
}
.coverLetter .Signature i {
    color: rgb(183, 183, 183);
    color: var(--cover-letter-light-italic);
    font-size: small;
}
.coverLetter .Signature>div:first-child {
    border-bottom:1px solid rgba(217,211,208,0.6);
    border-bottom:1px solid var(--light-border-color);
}
.coverLetter .Signature>div {
    width:180px;
}
.coverLetter .Signature img {
    max-width: 150px;
    margin-top:20px;
}

.coverLetter .Body {
    min-height: calc(297mm - 50% - 200px);
    color: rgb(255,255,255);
    color: var(--cover-letter-body-color);
    position: relative;
}

.coverLetter .Body .paragraphs {
    margin: 30px 0;
    font-family: 'Microsoft JhengHei Light', sans-serif;
}
.coverLetter .Body .BodyParas {
}


footer {
    grid-area: foot;
    padding:5px;
    text-align: center;
    font-size: small;
    font-style: italic;
    font-weight: lighter;
}

footer:after{

    opacity: 20%;
}

.Attribution, .Attribution a {
    font-size: 5px;
    font-style: italic;
    color: rgb(183, 183, 183);
    color: var(--cover-letter-light-italic);
}
.Attribution{
    padding:0 40px;
    display: flex;
    flex-flow: wrap;
    margin-top:10px;
}

.Attribution>*{
    margin:0 7px;
}

.headLarge {
    display:none;
}

.isOnline {
    display:none;
}
.imgPrint {display:none;}

@media screen and (max-width: 2200px) {
    .projectImage {
    }
    .projectImage img {
        margin-bottom:-3px;
    }
}
@media screen and (min-width: 2200px) {
    .App {
        display: grid;

        max-width: 90%;
        grid-template-areas:
            "head           head            headLarge"
            "main           aside           other"
            "coverLetter    coverLetter     other"
            "foot           foot            foot";

        grid-column-gap: 0;
        grid-column: auto;
        grid-template-columns: 1.5fr 1fr 3fr;
        grid-template-rows: 175px;
    }
    .headLarge {
        grid-area: headLarge;
        display: block;
        position: relative;
    }
    .projects>.projectsHeader {display: none;}
    .headLarge > .projectsHeader {
        display: block;
        position: absolute;
        bottom:20px;
        left:20px;
        padding:0;
        font-size: 1.1em;
    }
    .projects {
        margin-top:0; min-height: 100%;
        box-shadow: inset 50px 48px 30px -50px black;
    }
    .projectsList {background-color: transparent;}
    .projectImage {
        box-shadow: 1px 2px 5px 1px #000;
    }

    .projectImage img{

    }

    .coverLetter {
        margin-top:0;
        border-right: 1px dashed hsl(0, 0%, 35%);
        border-right: 1px dashed var(--dark-border-color);
    }
    .project p,.project h3 {
        font-size:calc(1em * 1.3);
    }
}

@media screen and (max-width: 700px) {
    .App {
        grid-template-areas:
            "head"
            "main"
            "aside"
            "coverLetter"
            "other"
            "foot";
        grid-template-columns: 1fr;
    }
    .projects {
        margin-top: 0;
        min-height: 300px;
        padding-bottom:10px;
    }
    .projectImage img {
        margin-bottom: -3px;
    }
    footer:after {
        opacity: 100%;
    }
}
@media screen and (max-width: 500px) {
    .coverLetter>div {
        padding:35px 30px 5px;
    }
    .App {
        box-shadow: none;
    }
    .projectsList {
        box-shadow: none;
    }
    body {background-color: #fff;}
}



@media print {
    @page{margin: 0;}
    body{
        font-size: 0.7rem;
        width: 210mm;
        height: 297mm;
        min-height: 297mm;
        margin: 0;
        /* change the margins as you want them to be. */
    }
    .sizer {
        height: calc(297mm - 175px);
    }
    .App {
        min-height: 297mm;
    }
    .projects {
        min-height: 297mm;
    }
    .coverLetter {
        font-size: 1.2em;
    }

    .pageSeperator {display:none;}
    footer {display: none;height:0;}
    .Attribution{display: none;}
    .otherProjects {
        min-height: 297mm;
    }
    .svg-inline--fa.fa-w-16 {
        width:16px;
    }

    .isOnline {display:block;}
    .imgPrint {display:block;}
    .imgWeb {display:none;}


    .coverLetter .Head {
        background-color: rgb(49, 49, 49);
    }
    .coverLetter .Head h2 {
        color: #eaeaea;
        text-shadow: black 1px 0px 10px;
    }

    .coverLetter {
        background-color: rgb(239, 239, 239);
    }
    .coverLetter .Body .paragraphs {
        color:hsl(160, 0%, 11%);
    }
    .coverLetter .Signature {
        color: hsl(150,0%, 22%);
    }
    .coverLetter .Signature i {
        color: hsl(160, 0%, 50%);
    }
    .coverLetter>div {
        padding: 0px 80px;
    }
    .coverLetter .Body .paragraphs {
        margin: 15px 0;
    }
    .coverLetter .Body .BodyParas {
        margin-top:40px;
    }

}
